






















import { Component, Mixins } from 'vue-property-decorator'

import UserProfileForm from '@/partials/forms/UserProfileForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    UserProfileForm
  }
})
export default class UsersCreate extends Mixins(FlashMessageMixin) {
  redirectToUsers () {
    const flashMessage = {
      message: 'Usuário cadastrado com sucesso.',
      isSuccess: true
    }

    this.$router.push('/users', () => {
      this.setFlashMessage(flashMessage)
    })
  }
}
